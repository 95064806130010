<template>
  <v-card elevation="0">
    <v-card-text class="pa-0 pt-4">
      <v-text-field
        ref="subject"
        v-model="subject"
        :label="$t('meetingScheduler.subject')"
        clearable
        :rules="[() => !!subject || $t('common.required')]"
      />
      <v-textarea
        ref="body"
        v-model="description"
        :label="$t('meetingScheduler.body')"
        filled
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['meetingSubject', 'meetingDescription']),

    subject: {
      get() {
        return this.meetingSubject;
      },
      set(value) {
        this.$store.dispatch('updateMeeting', { subject: value });
      },
    },

    description: {
      get() {
        return this.meetingDescription;
      },
      set(value) {
        this.$store.dispatch('updateMeeting', { description: value });
      },
    },
  },

  methods: {
    validateCompulsoryField() {
      return this.$refs.subject.validate(true);
    },
  },
};
</script>
