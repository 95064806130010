<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="6">
      <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            :label="$t('meetingScheduler.date')"
            prepend-icon="mdi-calendar"
            outlined
            dense
            readonly
            :rules="[() => date >= today || $t('messages.dateIsInPast')]"
            v-bind="attrs"
            hide-details
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="date"
          first-day-of-week="1"
          :locale="$i18n.locale"
          :events="getNationalHolidayEventColor"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col class="d-flex justify-center" cols="12" sm="6">
      <v-btn text outlined @click="$emit('set-today')">
        {{ $t('meetingScheduler.today') }}
      </v-btn>
      <v-btn icon @click="$emit('previous-week')">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn icon @click="$emit('next-week')">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-col>
    <v-col class="pt-0" cols="12" sm="6">
      <v-combobox
        ref="start_time"
        v-model="startTime"
        :menu-props="{ auto: true }"
        outlined
        dense
        hide-details
        :prepend-icon="$vuetify.breakpoint.smAndUp ? 'mdi-clock-outline' : ''"
        :label="$t('meetingScheduler.startTime')"
        :items="startEndTimes"
        :rules="[timeRules.required, timeRules.wrongTime]"
      />
    </v-col>
    <v-col class="pt-0" cols="12" sm="6">
      <v-combobox
        ref="end_time"
        v-model="endTime"
        :menu-props="{ auto: true }"
        outlined
        dense
        hide-details
        :label="$t('meetingScheduler.endTime')"
        :items="startEndTimes"
        :rules="[timeRules.required, timeRules.wrongTime]"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { addMinutes, differenceInMinutes, format } from 'date-fns';
import axiosCancelRequests from '../../../mixins/axiosCancelRequest';
import nationalHolidayDatePickerEvent from '../../../mixins/nationalHolidayDatePickerEvent';

export default {
  mixins: [nationalHolidayDatePickerEvent, axiosCancelRequests],

  data: () => ({
    isTimeValid: true,
  }),

  computed: {
    ...mapGetters([
      'meetingDate',
      'meetingStart',
      'meetingEnd',
      'meetingFacilities',
      'meeting',
    ]),

    date: {
      get() {
        return this.meetingDate;
      },
      set(value) {
        this.$store.dispatch('updateMeeting', { date: value });
      },
    },
    today() {
      return App.helpers.getDateOfToday();
    },

    startTime: {
      get() {
        return this.meetingStart;
      },
      set(value) {
        const oldStartTime = this.startTime;
        this.$store.dispatch('updateMeeting', {
          start: value,
        });
        this.setNewEndTime(value, oldStartTime, this.endTime);
      },
    },

    endTime: {
      get() {
        return this.meetingEnd;
      },
      set(value) {
        this.$store.dispatch('updateMeeting', { end: value });
      },
    },

    timeRules() {
      return {
        required: (value) => !!value || this.$t('common.required'),
        wrongTime: () => this.isTimeValid || 'Wrong Time Selected',
      };
    },
  },

  watch: {
    date() {
      this.generateCurrentMeetingEvent();
      this.checkValidity();
    },
    startTime() {
      this.generateCurrentMeetingEvent();
      this.checkValidity();
    },
    endTime() {
      this.generateCurrentMeetingEvent();
      this.checkValidity();
    },
    meetingFacilities() {
      this.checkValidity();
      this.validateCompulsoryField();
    },
  },

  created() {
    this.calculateStartEndTime();
    this.generateCurrentMeetingEvent();
  },

  methods: {
    setNewEndTime(newStartTime, startTime, endTime) {
      if (startTime > endTime) {
        return;
      }
      const start = App.helpers.getDateObject(`2020-01-01 ${startTime}`);
      const end = App.helpers.getDateObject(`2020-01-01 ${endTime}`);
      const newStart = App.helpers.getDateObject(`2020-01-01 ${newStartTime}`);
      const minutesDifference = differenceInMinutes(end, start);
      const newEnd = addMinutes(newStart, minutesDifference);
      this.endTime = format(newEnd, 'HH:mm');
    },
    calculateStartEndTime() {
      const allowedStartEndTimes = [];
      for (let i = 0; i < 24; i += 1) {
        for (let j = 0; j < 60; j += 15) {
          allowedStartEndTimes.push(this.hourMinute(i, j));
        }
      }
      this.startEndTimes = allowedStartEndTimes;
    },

    generateCurrentMeetingEvent() {
      if (this.date !== '' && this.startTime !== '' && this.endTime !== '') {
        this.$store.dispatch('updateCurrentMeetingEvent', {
          name: 'Meeting',
          start: `${this.date} ${this.startTime}`,
          end: `${this.date} ${this.endTime}`,
          color: 'grey',
        });
      }
    },

    hourMinute(hour, minute) {
      let hourString = hour.toString();
      let minuteString = minute.toString();
      let hourMinuteString = '';
      if (hourString.length === 1) {
        hourString = `0${hourString}`;
      }
      if (minuteString.length === 1) {
        minuteString = `0${minuteString}`;
      }
      hourMinuteString = `${hourString}:${minuteString}`;
      return hourMinuteString;
    },

    async checkValidity() {
      if (this.isNotTime(this.startTime) && this.isNotTime(this.endTime)) {
        this.isTimeValid = false;
        return;
      }
      if (this.startTime >= this.endTime) {
        this.isTimeValid = false;
        return;
      }
      if (await this.isOverlappingWithFacility()) {
        this.isTimeValid = false;
        return;
      }
      this.isTimeValid = true;
    },

    isNotTime(value) {
      return value.match(/([0-1][0-9]|2[0-3]):[0-5][0-9]/) == null;
    },

    async isOverlappingWithFacility() {
      const facilities = [...this.meetingFacilities];
      if (facilities.length === 0) {
        return false;
      }
      this.cancelExistingRequests();
      const { data } = await axios.get('/api/facility-booking/get-overlap/', {
        params: {
          facilities,
          all_day: 0,
          start: `${this.meeting.date} ${this.meeting.start.slice(0, 5)}:00`,
          end: `${this.meeting.date} ${this.meeting.end.slice(0, 5)}:00`,
          group_type: 'App\\Meeting',
          group_id: this.meeting.id,
          recurrence_type: 'none',
        },
        cancelToken: this.getCancelToken(),
      });
      return typeof data === 'object';
    },

    validateCompulsoryField() {
      return (
        this.$refs.start_time.validate(true) &&
        this.$refs.end_time.validate(true)
      );
    },
  },
};
</script>
