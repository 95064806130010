<template>
  <v-menu
    v-model="eventDetailsMenu"
    offset-x
    :close-on-content-click="false"
    :position-x="menuX"
    :position-y="menuY"
  >
    <v-card>
      <v-btn
        fab
        icon
        absolute
        right
        x-small
        class="mt-2"
        @click="eventDetailsMenu = false"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="menuDetails.events"
          item-key="index"
          disable-sort
          hide-default-footer
        >
          <template #[`item.name`]="{ item }">
            {{ getName(item) }}
          </template>
          <template #[`item.subject`]="{ item }">
            {{ item.subject ? item.subject : $t('event.busy') }}
          </template>
          <template #[`item.time`]="{ item }">
            {{ getTimeDisplay(item) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { isSameDay } from 'date-fns';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    eventDetailsMenu: false,
    menuX: 0,
    menuY: 0,
    menuDetails: {
      heading: '',
      events: [],
      color: '',
    },
  }),
  computed: {
    ...mapGetters(['facility', 'calendarOwners']),
    headers() {
      const name =
        this.menuDetails.heading === ''
          ? 'name'
          : this.$t(`meetingScheduler.${this.menuDetails.heading}`);
      return [
        { text: name, value: 'name' },
        { text: this.$t('meetingScheduler.subject'), value: 'subject' },
        { text: this.$t('meetingScheduler.time'), value: 'time' },
      ];
    },
  },
  methods: {
    openMenu(event, menuX, menuY) {
      this.eventDetailsMenu = false;
      this.menuX = menuX;
      this.menuY = menuY;
      this.menuDetails.heading = event.name;
      this.menuDetails.events = [...event.events];
      this.menuDetails.color = event.color;
      setTimeout(() => {
        this.eventDetailsMenu = true;
      }, 10);
    },
    getName(item) {
      if (this.menuDetails.heading === 'Facilities') {
        return this.facility(item.facility_id).name;
      }
      const calendarOwner = this.calendarOwners.find((owner) => {
        if (owner.type && owner.type === 'user') {
          return owner.calendars.some(
            (calendar) => calendar.id === item.calendar_id,
          );
        }
        return false;
      });
      return calendarOwner.name;
    },
    getTimeDisplay(item) {
      const start = App.helpers.getDateObject(item.start);
      const end = App.helpers.getDateObject(item.end);
      if (!item.all_day) {
        return `${this.$d(start, 'eventTime')} ~ ${this.$d(end, 'eventTime')}`;
      }
      if (isSameDay(start, end)) {
        return `${this.$d(start, 'allDayEvent')}`;
      }
      return `${this.$d(start, 'allDayEvent')} ~
        ${this.$d(end, 'allDayEvent')}`;
    },
  },
};
</script>
