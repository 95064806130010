<template>
  <v-card outlined>
    <v-sheet :height="calendarHeight">
      <v-calendar
        ref="calendar"
        v-model="calendarDate"
        type="week"
        color="blue lighten-3"
        :events="calendarEvents"
        event-overlap-mode="column"
        :weekdays="weekdays"
        :event-color="getEventColor"
        :short-weekdays="true"
        :locale="$i18n.locale"
        @mousedown:event="startDrag"
        @mouseup:event="showEvent"
        @mousemove:time="mouseMove"
        @mouseup:time="endDrag"
      >
        <template #event="{ event }">
          <div
            v-if="event.color === 'grey'"
            class="v-event-drag-bottom"
            @mousedown.stop="isEventExtending = true"
          />
          <div v-if="event.isNationalHoliday">
            {{ displayNationalHoliday(event) }}
          </div>
          <div v-if="event.all_day === 1">
            <span class="text-caption pl-2">
              {{
                event.name === 'Members'
                  ? $t('meetingScheduler.allDayBusy')
                  : $t('meetingScheduler.allDayBooked')
              }}
            </span>
          </div>
        </template>
      </v-calendar>
    </v-sheet>
  </v-card>
</template>

<script>
import { startOfWeek, endOfWeek, format } from 'date-fns';
import { mapGetters } from 'vuex';
import axiosCancelRequests from '../../../mixins/axiosCancelRequest';

export default {
  mixins: [axiosCancelRequests],
  data: () => ({
    calendarHeight: '500',
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    isEventExtending: false,
    isEventBeingDragged: false,
    eventClicked: false,
    initialDragTime: '',
    blocksOf15Min: {
      startAndInitialDrag: 0,
      endAndInitialDrag: 0,
      startAndEnd: 0,
    },
    memberEvents: [],
    facilityEvents: [],
  }),
  computed: {
    ...mapGetters([
      'meeting',
      'meetingMembers',
      'meetingFacilities',
      'editMeetingEvent',
      'currentMeetingEvent',
      'nationalHolidaysAsEvents',
      'nationalHolidays',
    ]),
    calendarDate: {
      get() {
        return this.meeting.date;
      },
      set(value) {
        this.$store.dispatch('updateMeeting', { date: value });
      },
    },
    weekStartEndDate() {
      const initialDate = App.helpers.getDateObject(this.meeting.date);
      let startDate = startOfWeek(initialDate, { weekStartsOn: 1 });
      let endDate = endOfWeek(initialDate, { weekStartsOn: 1 });
      startDate = format(startDate, 'yyyy-MM-dd');
      endDate = format(endDate, 'yyyy-MM-dd');
      return { startDate, endDate };
    },
    calendarEvents() {
      let events = [];
      events = events.concat(this.nationalHolidaysInWeek);
      if (this.meeting.id !== 0) {
        events.push(this.editMeetingEvent);
      }
      if (
        this.currentMeetingEvent.start !== '' &&
        this.currentMeetingEvent.end !== ''
      ) {
        events.push(this.currentMeetingEvent);
      }
      events = events.concat(this.memberEvents);
      events = events.concat(this.facilityEvents);
      return events;
    },
    nationalHolidaysInWeek() {
      return this.nationalHolidaysAsEvents.filter((event) => {
        const start = this.weekStartEndDate.startDate;
        const end = this.weekStartEndDate.endDate;
        return event.date >= start && event.date < end;
      });
    },
  },
  watch: {
    meetingMembers() {
      this.updateEvents();
    },
    meetingFacilities() {
      this.updateEvents();
    },
    calendarDate() {
      this.updateEvents();
    },
  },
  mounted() {
    this.updateEvents();
    this.$nextTick(() => {
      this.$refs.calendar.scrollToTime('09:00');
    });
  },
  methods: {
    displayNationalHoliday(event) {
      event.color = 'pink';
      return event.name;
    },
    updateEvents() {
      this.cancelExistingRequests();
      this.getBusyTimesForMembers(this.meetingMembers);
      this.getBusyTimesForFacilities(this.meetingFacilities);
    },
    async getBusyTimesForMembers(members) {
      const dates = this.weekStartEndDate;
      let events = [];
      if (members.length > 0) {
        try {
          const response = await axios.get(
            '/api/meeting-scheduler/members-schedules',
            {
              params: {
                members,
                meetingId: this.meeting.id,
                color: 'green',
                name: 'Members',
                start: dates.startDate.concat(' 00:00:00'),
                end: dates.endDate.concat(' 23:59:59'),
              },
              cancelToken: this.getCancelToken(),
            },
          );
          events = response.data;
          this.memberEvents = this.fixStartEndForAllDayEvents(events);
        } catch (error) {
          //
        }
      } else {
        this.memberEvents = this.fixStartEndForAllDayEvents(events);
      }
    },
    async getBusyTimesForFacilities(facilities) {
      const dates = this.weekStartEndDate;
      if (facilities.length > 0) {
        try {
          const { data } = await axios.get(
            '/api/meeting-scheduler/facilities-schedule',
            {
              params: {
                facilities,
                meetingId: this.meeting.id,
                color: 'orange',
                name: 'Facilities',
                start: dates.startDate.concat(' 00:00:00'),
                end: dates.endDate.concat(' 23:59:59'),
              },
              cancelToken: this.getCancelToken(),
            },
          );
          this.facilityEvents = this.fixStartEndForAllDayEvents(data);
        } catch (error) {
          //
        }
      } else {
        this.facilityEvents = this.fixStartEndForAllDayEvents([]);
      }
    },
    fixStartEndForAllDayEvents(events) {
      return events.map((event) => {
        const start = App.helpers.getDateObject(event.start);
        const end = App.helpers.getDateObject(event.end);
        if (event.all_day === 1) {
          event.start = format(start, 'yyyy-MM-dd');
          event.end = format(end, 'yyyy-MM-dd');
        }
        return event;
      });
    },
    getEventColor(event) {
      return event.color;
    },
    previousDayOrWeek() {
      this.$refs.calendar.prev();
    },
    nextDayOrWeek() {
      this.$refs.calendar.next();
    },
    setToday() {
      this.calendarDate = App.helpers.getDateOfToday();
    },
    startDrag({ event }) {
      if (event.name === 'Meeting') {
        this.isEventBeingDragged = true;
      }
    },
    showEvent({ nativeEvent, event }) {
      if (event.name === 'Members' || event.name === 'Facilities') {
        this.eventClicked = true;
        this.$emit(
          'event-display',
          event,
          nativeEvent.clientX,
          nativeEvent.clientY,
        );
      }
    },
    mouseMove(tms) {
      if (this.isEventExtending === true) {
        this.$store.dispatch('updateMeeting', {
          end: this.extendTimeGeneration(tms.time),
        });
      }
      if (this.isEventBeingDragged === true) {
        if (this.initialDragTime === '') {
          this.initialDragTime = tms.time;
          this.generateBlocks(tms.time);
        }
        this.$store.dispatch(
          'updateMeeting',
          this.dragTimeGeneration(tms.date, tms.time),
        );
      }
    },
    endDrag(eventDetails) {
      if (
        this.isEventExtending != true &&
        this.isEventBeingDragged != true &&
        this.eventClicked != true
      ) {
        this.addDetails(eventDetails);
      }
      if (this.isEventExtending === true) {
        this.isEventExtending = false;
      }
      if (this.isEventBeingDragged === true) {
        this.initialDragTime = '';
        this.isEventBeingDragged = false;
      }
      this.eventClicked = false;
    },
    addDetails(eventDetails) {
      this.generateBlocks(this.meeting.start);
      this.$store.dispatch(
        'updateMeeting',
        this.dragTimeGeneration(eventDetails.date, eventDetails.time),
      );
    },
    extendTimeGeneration(time) {
      const hour = parseInt(time.slice(0, 2), 10);
      const minute = parseInt(time.slice(3, 5), 10);
      const quarterPosition = Math.ceil(minute / 15);
      let newMinute = quarterPosition * 15 === 60 ? 0 : quarterPosition * 15;
      let newHour = quarterPosition * 15 === 60 ? hour + 1 : hour;
      if (newHour >= 24) {
        newMinute = '59';
        newHour = '23';
      } else if (newHour < 0) {
        newMinute = '00';
        newHour = '00';
      } else {
        newHour = newHour.toString();
        newMinute = newMinute.toString();
      }
      newMinute = newMinute.length === 1 ? '0'.concat(newMinute) : newMinute;
      newHour = newHour.length === 1 ? '0'.concat(newHour) : newHour;
      return newHour.concat(':'.concat(newMinute));
    },
    generateBlocks(time) {
      const startMinutes = this.generateMinutes(this.meeting.start);
      const endMinutes = this.generateMinutes(this.meeting.end);
      const dragMinutes = this.generateMinutes(time);
      this.blocksOf15Min.startAndEnd = Math.ceil(
        (endMinutes - startMinutes) / 15,
      );
      const startToDrag = Math.ceil((dragMinutes - startMinutes) / 15);
      if (startToDrag > this.blocksOf15Min.startAndEnd) {
        this.blocksOf15Min.startAndInitialDrag = this.blocksOf15Min.startAndEnd;
        this.blocksOf15Min.endAndInitialDrag = 0;
      } else {
        this.blocksOf15Min.startAndInitialDrag = startToDrag;
        this.blocksOf15Min.endAndInitialDrag =
          this.blocksOf15Min.startAndEnd - startToDrag;
      }
    },
    generateMinutes(time) {
      return (
        parseInt(time.slice(0, 2), 10) * 60 + parseInt(time.slice(3, 5), 10)
      );
    },
    dragTimeGeneration(date, time) {
      const minutesToStart = this.blocksOf15Min.startAndInitialDrag * 15;
      const minutesToEnd = this.blocksOf15Min.endAndInitialDrag * 15;
      return {
        date,
        start: this.newTimeCalculator(time, 'minus', minutesToStart),
        end: this.newTimeCalculator(time, 'add', minutesToEnd),
      };
    },
    newTimeCalculator(time, addOrMinus, minutes) {
      const totalMinutes =
        addOrMinus === 'add'
          ? this.generateMinutes(time) + minutes
          : this.generateMinutes(time) - minutes;
      const newHour = Math.floor(totalMinutes / 60);
      let newMinutes = totalMinutes % 60;
      const offBy = newMinutes % 15;
      newMinutes -= offBy;
      const d = new Date();
      d.setHours(newHour);
      d.setMinutes(newMinutes);
      return format(d, 'HH:mm');
    },
  },
};
</script>

<style lang="scss">
.v-event-timed.red {
  opacity: 0.4;
}
.v-event-timed.yellow {
  opacity: 0.2;
}
.v-event-timed.green {
  opacity: 0.4;
}
.v-event-timed.orange {
  opacity: 0.5;
}
.v-event-timed.grey {
  opacity: 0.7;
}
.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>
