<template>
  <v-container style="max-width: 1300px">
    <v-row class="px-4 pb-4 headline">
      <v-col cols="12" sm="6" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
        {{ $t('meetingScheduler.upcomingMeetings') }}
      </v-col>
      <v-col class="d-flex justify-end" cols="12" sm="6">
        <v-btn color="primary" @click="newMeeting">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('meetingScheduler.createMeeting') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="meetings"
          show-expand
          :expanded.sync="expandedItems"
          :mobile-breakpoint="960"
          style="cursor: pointer"
          :loading="isLoading"
          @click:row="toggleExpand"
        >
          <template #[`item.actions`]="{ item }">
            <v-tooltip bottom :disabled="$vuetify.breakpoint.xsOnly">
              <template #activator="{ on }">
                <v-btn
                  icon
                  class="mx-1"
                  @click="editMeetingAll(item.id)"
                  v-on="on"
                >
                  <v-icon size="20">mdi-pencil-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('common.edit') }}</span>
            </v-tooltip>
            <v-tooltip bottom :disabled="$vuetify.breakpoint.xsOnly">
              <template #activator="{ on }">
                <v-btn
                  icon
                  class="mx-1"
                  @click="
                    isActionClicked = true;
                    duplicateMeeting(item);
                  "
                  v-on="on"
                >
                  <v-icon size="20">mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('meetingScheduler.duplicate') }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="isMeetingDeletable(item)"
              bottom
              :disabled="$vuetify.breakpoint.xsOnly"
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  class="mx-1"
                  @click="
                    isActionClicked = true;
                    confirmDelete(item.id);
                  "
                  v-on="on"
                >
                  <v-icon size="20">mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('common.delete') }}</span>
            </v-tooltip>
          </template>
          <template #[`item.response`]="{ item }">
            <v-radio-group
              v-if="item.pivot"
              v-model="item.pivot.attending_status"
              @change="
                isActionClicked = true;
                markAttendance(item);
              "
            >
              <v-radio
                :label="$t('meetingScheduler.going')"
                :value="1"
              ></v-radio>
              <v-radio
                :label="$t('meetingScheduler.notGoing')"
                :value="0"
              ></v-radio>
              <v-radio
                :label="$t('meetingScheduler.undecided')"
                :value="'null'"
              ></v-radio>
            </v-radio-group>
          </template>
          <template #expanded-item="{ item }">
            <td class="py-2" :colspan="headers.length">
              <MeetingDetailsDisplay :meeting="item" />
            </td>
          </template>
          <template #[`item.start`]="{ item }">
            <td>
              {{ $d(App.helpers.getDateObject(item.start), 'allDayEvent') }}
              {{ item.start.slice(11, 16) }} ~ {{ item.end.slice(11, 16) }}
            </td>
          </template>
          <template #[`item.subject`]="{ item }">
            <td class="title">{{ item.subject }}</td>
          </template>
          <template #[`item.organizer`]="{ item }">
            <td v-for="(user, index) in users" :key="index">
              <span v-if="item.created_by == user.id">{{ user.name }}</span>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <confirm ref="confirm" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import MeetingDetailsDisplay from './components/MeetingDetailsDisplay.vue';
import Confirm from '../../components/Confirm.vue';

import dataRefresher from '../../mixins/dataRefresher';
import meetingAttendanceResponse from '../../mixins/meetingAttendanceResponse';
import { updateAttendance } from '../../api/meetings.api';
import { sendGoogleAnalyticsEvent } from '../../plugins/vue-gtag';

export default {
  components: {
    MeetingDetailsDisplay,
    Confirm,
  },
  mixins: [dataRefresher, meetingAttendanceResponse],
  data: () => ({
    sortBy: 'date',
    sortDesc: false,
    isActionClicked: false,
    expandedItems: [],
    meetings: [],
    users: [],
    isLoading: true,
  }),
  computed: {
    ...mapGetters(['id']),
    headers() {
      return [
        {
          text: this.$t('meetingScheduler.time'),
          value: 'start',
          align: 'center',
          width: '220px',
        },
        {
          text: this.$t('meetingScheduler.subject'),
          value: 'subject',
          align: 'center',
          width: '380px',
          sortable: false,
        },
        {
          text: this.$t('meetingScheduler.organizer'),
          value: 'organizer',
          align: 'center',
          width: '200px',
          sortable: false,
        },
        {
          text: this.$t('common.actions'),
          value: 'actions',
          align: 'center',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t('meetingScheduler.response'),
          value: 'response',
          align: 'center',
          width: '100px',
          sortable: false,
        },
      ];
    },
  },

  mounted() {
    this.setUpRefresher(this.getUserMeetings);
  },

  created() {
    this.getUsers();
    this.getUserMeetings();
  },

  methods: {
    toggleExpand(event, state) {
      if (this.isActionClicked) {
        return;
      }
      if (state.isExpanded === false) {
        this.expandedItems.push(event);
        return;
      }
      this.expandedItems = this.expandedItems.filter(
        (item) => item.id !== event.id,
      );
    },

    async getUsers() {
      const response = await axios.get('/api/users/');
      this.users = response.data;
    },

    async getUserMeetings() {
      this.isLoading = true;
      const { data } = await axios.get(`/api/users/${this.id}/meetings`);
      this.meetings = data.map((meeting) => {
        if (meeting.pivot && meeting.pivot.attending_status === null) {
          meeting.pivot.attending_status = 'null';
          return meeting;
        }
        return meeting;
      });
      this.isLoading = false;
    },

    async markAttendance(meeting) {
      const attendanceResponse = meeting.pivot.attending_status;
      const decisionToSend =
        this.getMeetingAttendanceResponse(attendanceResponse);
      try {
        await updateAttendance(meeting, decisionToSend);
      } catch (e) {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
        this.isActionClicked = false;
        return;
      }
      this.getUserMeetings();
      this.showSuccessSnackbar(decisionToSend);
      this.isActionClicked = false;
    },

    newMeeting() {
      sendGoogleAnalyticsEvent('userClickedOnCreateMeetingButton');
      this.$router.push({
        name: 'schedule-meeting',
        params: { isEditing: false },
      });
    },

    async confirmDelete(meetingId) {
      sendGoogleAnalyticsEvent('userClickedOnDeleteMeetingIcon', meetingId);
      const confirmation = await this.$refs.confirm.open(
        this.$t('meetingScheduler.delete'),
        this.$t('meetingScheduler.deleteBody'),
      );
      if (!confirmation) {
        return;
      }
      this.deleteMeeting(meetingId);
    },
    async deleteMeeting(meetingId) {
      await axios.delete(`/api/meetings/${meetingId}`);
      this.getUserMeetings();
      this.isActionClicked = false;
      sendGoogleAnalyticsEvent('deletedMeetingFromPage', meetingId);
    },

    editMeetingAll(id) {
      sendGoogleAnalyticsEvent('userClickedOnEditMeetingIcon', id);
      this.$router.push({
        name: 'schedule-meeting',
        params: { id, isEditing: true },
      });
    },

    isMeetingDeletable(meeting) {
      return meeting.created_by === this.id;
    },

    duplicateMeeting(meeting) {
      this.$router.push({
        name: 'schedule-meeting',
        params: { id: meeting.id, isEditing: false },
      });
    },
  },
};
</script>
