<template>
  <v-bottom-sheet :value="true" scrollable persistent fullscreen>
    <v-card elevation-0 :ripple="false">
      <v-card-title class="pl-2">
        <v-btn icon class="mr-2" @click="redirect">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        {{ $t('meetingScheduler.scheduleMeeting') }}
      </v-card-title>
      <v-divider />
      <v-card-text class="px-0">
        <v-container fluid>
          <CreateMeeting
            ref="createMeeting"
            @previous-week="$refs.meetingSchedulerWeekView.previousDayOrWeek()"
            @next-week="$refs.meetingSchedulerWeekView.nextDayOrWeek()"
            @set-today="$refs.meetingSchedulerWeekView.setToday()"
          />
          <v-row class="pl-7 pr-7 pb-3">
            <v-icon small color="green"> mdi-checkbox-blank </v-icon>
            {{ $t('meetingScheduler.membersEvents') }}
            <v-icon class="ml-4" small color="orange">
              mdi-checkbox-blank
            </v-icon>
            {{ $t('meetingScheduler.facilitiesEvents') }}
          </v-row>

          <v-row>
            <v-col class="py-0" cols="12" sm="7">
              <MeetingSchedulerWeekView
                ref="meetingSchedulerWeekView"
                @event-display="eventDisplay"
              />
            </v-col>
            <v-col cols="12" sm="5" class="py-0 px-7">
              <MeetingDetails ref="meetingDetails" />
              <v-btn
                class="mt-3"
                block
                :loading="isLoading"
                color="primary"
                @click="validateCompulsoryFields"
              >
                {{ $t('common.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <EventDetails ref="eventDetails" />
  </v-bottom-sheet>
</template>

<script>
import { mapGetters } from 'vuex';
import { createMeeting, updateMeeting } from '../../api/meetings.api';
import CreateMeeting from './components/CreateMeeting.vue';
import MeetingSchedulerWeekView from './components/MeetingSchedulerWeekView.vue';
import MeetingDetails from './components/MeetingDetails.vue';
import EventDetails from './components/EventDetails.vue';

export default {
  components: {
    CreateMeeting,
    EventDetails,
    MeetingSchedulerWeekView,
    MeetingDetails,
  },
  data: () => ({
    isLoading: false,
    editDetails: {
      id: 0,
      date: '',
      start: '',
      end: '',
      event_type_id: null,
      subject: '',
      description: '',
      members: [],
      facilities: [],
    },
    eventTypeChoices: [],
  }),
  computed: {
    ...mapGetters(['id', 'meeting']),
  },
  created() {
    this.getDefaultData();
  },
  methods: {
    getDefaultData() {
      this.editDetails = {
        ...this.editDetails,
        ...{
          date: App.helpers.getDateOfToday(),
          start: '09:00',
          end: '10:00',
        },
      };
      this.$store.dispatch('updateMeeting', this.editDetails);

      if (this.$route.params.isEditing === true) {
        this.editMeetingInitializer(this.$route.params.id);
        return;
      }
      if (this.$route.params.id) {
        const meetingId = this.$route.params.id;
        this.duplicatedMeetingInitializer(meetingId);
      }
    },

    async duplicatedMeetingInitializer(meetingId) {
      this.editDetails.id = 0;
      await this.getEditableMeetingInformation(meetingId);
      await this.getEditableMeetingMembers(meetingId);
      await this.getEditableMeetingFacilities(meetingId);
      this.$store.dispatch('updateMeeting', { ...this.editDetails });
      this.generateEditMeetingEvent();
    },

    async editMeetingInitializer(meetingId) {
      this.editDetails.id = parseInt(meetingId, 10);
      await this.getEditableMeetingInformation(meetingId);
      await this.getEditableMeetingMembers(meetingId);
      await this.getEditableMeetingFacilities(meetingId);
      this.$store.dispatch('updateMeeting', { ...this.editDetails });
      this.generateEditMeetingEvent();
    },

    async getEditableMeetingInformation(meetingId) {
      const response = await axios.get(
        `/api/meeting-scheduler/get_meeting/${meetingId}`,
      );

      const meeting = response.data;
      this.editDetails.subject = meeting.subject;
      this.editDetails.description = meeting.description;
      this.editDetails.event_type_id = meeting.event_type_id;
      this.editDetails.date = meeting.start.slice(0, 10);
      this.editDetails.start = meeting.start.slice(11, 16);
      this.editDetails.end = meeting.end.slice(11, 16);
    },

    async getEditableMeetingMembers(meetingId) {
      const response = await axios.get(
        `/api/meeting-scheduler/get_meeting_members/${meetingId}`,
      );
      const members = response.data;
      for (let i = 0; i < members.length; i += 1) {
        this.editDetails.members.push(members[i].id);
      }
    },

    async getEditableMeetingFacilities(meetingId) {
      const response = await axios.get(
        `/api/meeting-scheduler/get-meeting-facilities/${meetingId}`,
      );
      const facilities = response.data;
      for (let i = 0; i < facilities.length; i += 1) {
        this.editDetails.facilities.push(facilities[i].id);
      }
    },

    generateEditMeetingEvent() {
      this.$store.dispatch('updateEditMeetingEvent', {
        name: 'Edit Meeting',
        start: `${this.editDetails.date} ${this.editDetails.start}`,
        end: `${this.editDetails.date} ${this.editDetails.end}`,
        color: 'yellow',
      });
    },

    eventDisplay(event, clientX, clientY) {
      this.$refs.eventDetails.openMenu(event, clientX, clientY);
    },

    validateCompulsoryFields() {
      if (
        this.$refs.meetingDetails.validateCompulsoryField() &&
        this.$refs.createMeeting.validateCompulsoryField()
      ) {
        this.addEditMeeting();
      }
    },

    async addEditMeeting() {
      this.isLoading = true;
      const start = `${this.meeting.date} ${this.meeting.start}:00`;
      const end = `${this.meeting.date} ${this.meeting.end}:00`;
      const facilities = [...this.meeting.facilities];
      try {
        if (this.meeting.id === 0) {
          await createMeeting(
            this.meeting.subject,
            this.meeting.description,
            this.meeting.event_type_id,
            start,
            end,
            this.meeting.members,
            facilities,
          );
        } else {
          await updateMeeting(
            this.meeting.id,
            this.meeting.subject,
            this.meeting.description,
            this.meeting.event_type_id,
            start,
            end,
            this.meeting.members,
            facilities,
          );
        }
        this.isLoading = false;
        this.redirect();
        this.$store.dispatch('openSnackbar', {
          message: this.$t('messages.meetingCreatedSuccessfully'),
          color: 'success',
          timeout: 2000,
        });
      } catch {
        this.$store.dispatch('openSnackbar', {
          message: this.$t('messages.meetingCreationFailed'),
          color: 'error',
          timeout: 2000,
        });
        this.isLoading = false;
      }
    },

    redirect() {
      this.$router.push({ name: 'meeting-scheduler' });
    },
  },
};
</script>
