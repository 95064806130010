<template>
  <v-row class="pb-4" align="center" justify="space-between">
    <v-col cols="12" sm="5">
      <start-end-time-selector
        ref="startEndTimeSelector"
        @previous-week="$emit('previous-week')"
        @next-week="$emit('next-week')"
        @set-today="$emit('set-today')"
      />
    </v-col>
    <v-divider vertical class="mt-1 hidden-sm-and-down" />
    <v-col cols="12" sm="6">
      <v-row>
        <v-col cols="12" sm="6">
          <MultipleUsersSelector
            v-model="members"
            :items="items"
            :rules="[(v) => v.length !== 0 || $t('common.required')]"
            outlined
            :label="$t('meetingScheduler.members')"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <facilities-selector v-model="facilities" />
        </v-col>
        <v-col class="pt-1" cols="12" sm="6">
          <event-type-selector v-model="event_type_id" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import StartEndTimeSelector from './StartEndTimeSelector.vue';
import EventTypeSelector from '../../../components/EventTypeSelector.vue';
import MultipleUsersSelector from '../../../components/MultipleUsersSelector.vue';
import FacilitiesSelector from '../../../components/Selectors/FacilitiesSelector.vue';
import meetingMembersSelectorItems from '../../../mixins/meetingMembersSelectorItems';

export default {
  components: {
    StartEndTimeSelector,
    EventTypeSelector,
    FacilitiesSelector,
    MultipleUsersSelector,
  },

  mixins: [meetingMembersSelectorItems],

  data() {
    return {
      members: [],
    };
  },

  computed: {
    ...mapGetters([
      'id',
      'meetingMembers',
      'meetingFacilities',
      'meetingEventTypeId',
    ]),

    event_type_id: {
      get() {
        return this.meetingEventTypeId;
      },
      set(value) {
        this.$store.dispatch('updateMeeting', { event_type_id: value });
      },
    },

    facilities: {
      get() {
        return this.meetingFacilities;
      },
      set(value) {
        this.$store.dispatch('updateMeeting', { facilities: value });
      },
    },
  },

  watch: {
    members(value) {
      this.updateMeetingMembers(value);
    },

    meetingMembers(value) {
      this.updateMembers(value);
    },
  },

  created() {
    this.setMembers();
  },

  methods: {
    setMembers() {
      if (this.meetingMembers.length > 0) {
        this.setMembersForEditCase();
      } else {
        this.setMembersForAddCase();
      }
    },

    setMembersForEditCase() {
      this.updateMembers(this.meetingMembers);
    },

    setMembersForAddCase() {
      const authUser = this.getItem(this.id);
      this.members = [authUser];
    },

    updateMembers(meetingMembers) {
      this.members = meetingMembers.map(this.getItem);
    },

    getItem(userId) {
      return this.items.find(
        (item) => item.id === userId && item.type === 'user',
      );
    },

    updateMeetingMembers(members) {
      const memberIds = members.map((member) => member.id);
      if (areTwoArraysEqual(memberIds, this.meetingMembers)) {
        return;
      }
      this.$store.dispatch('updateMeeting', { members: memberIds });
    },

    validateCompulsoryField() {
      const isMembersError = this.members.length !== 0;
      const isTimeError =
        this.$refs.startEndTimeSelector.validateCompulsoryField();
      return isMembersError && isTimeError;
    },
  },
};

function areTwoArraysEqual(array1, array2) {
  return (
    array1.length === array2.length &&
    array1.every((element, index) => element === array2[index])
  );
}
</script>
