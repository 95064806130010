<template>
  <v-container>
    <div class="title font-weight-bold">
      {{ title }}
    </div>
    <div class="headline font-weight-bold">
      {{ meeting.subject }}
    </div>

    <DetailsDisplayElement
      v-if="meeting.description"
      class="pt-3"
      :message="formatDescription(meeting.description)"
      :tooltip-text="$t('meetingScheduler.body')"
      :is-html-view="true"
      icon="mdi-text"
    />

    <DetailsDisplayMenu
      class="pt-4"
      :icon="'mdi-account-group'"
      :details="members"
      color="grey lighten-2"
    />

    <DetailsDisplayMenu
      v-if="facilities.length != 0"
      class="pt-2"
      :icon="'mdi-seat'"
      :details="facilities"
      color="orange lighten-4"
    />
  </v-container>
</template>

<script>
import { format, differenceInCalendarDays } from 'date-fns';
import DetailsDisplayMenu from '../../../components/DetailsDisplayMenu.vue';
import DetailsDisplayElement from '../../../components/DetailsDisplayElement.vue';

import calendarAndBookings from '../../../mixins/calendarAndBookings';

export default {
  components: {
    DetailsDisplayMenu,
    DetailsDisplayElement,
  },

  mixins: [calendarAndBookings],

  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    members: [],
    facilities: [],
  }),

  computed: {
    title() {
      const startTime = format(new Date(this.meeting.start), 'HH:mm');
      const endTime = format(new Date(this.meeting.end), 'HH:mm');
      return `${startTime} - ${endTime}, ${this.dateDisplay}`;
    },

    dateDisplay() {
      const meetingDay = new Date(this.meeting.start);
      const today = new Date();
      const daysLater = differenceInCalendarDays(meetingDay, today);

      switch (daysLater) {
        case 0:
          return this.$t('meetingScheduler.today');
        case 1:
          return this.$t('meetingScheduler.tomorrow');
        default:
          return this.$t('meetingScheduler.daysLater', { days: daysLater });
      }
    },
  },

  watch: {
    meeting() {
      this.getMeetingDetails();
    },
  },

  created() {
    this.getMeetingDetails();
  },

  methods: {
    getMeetingDetails() {
      this.getMeetingMembers();
      this.getMeetingFacilities();
    },
    async getMeetingMembers() {
      this.members = [];
      const { data } = await axios.get(
        `/api/notifications/meeting-members-and-decision/${this.meeting.id}`,
      );
      this.members = [...data];
    },
    async getMeetingFacilities() {
      this.facilities = [];
      const { data } = await axios.get(
        `/api/meeting-scheduler/get-meeting-facilities/${this.meeting.id}`,
      );
      this.facilities = [...data];
    },
  },
};
</script>
